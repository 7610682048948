import React from "react";
import { SearchOutlined } from "@ant-design/icons";
import { useMemo } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { getProp, getPropString } from "../../lib/functions";
import { SyncOutlined } from "@ant-design/icons";
import { Input, LinearProgress } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { useRef } from "react";
import { ColumnHeader } from "./columnHeader";
import { Row } from "./row";
import { RowGrid } from "./rowGrid";
import { LIGHT_GRAY } from "../../lib/definitions";

export interface SuperTableProps {
  rows: any[];
  columns: SuperTableColumn[];
  rowKey: string | ((row: any) => string);
  isRefreshing?: boolean;
  selectedRow?: any;
  multiSelection?: {
    selected: any[];
    setSelected: React.Dispatch<React.SetStateAction<any[]>>;
  };
  searchProps?: {
    searchValue: string;
    setSearchValue(searchValue: string): void;
  };
  className?: string;
  itemsPerPage?: number;
  removePagination?: boolean;
  removeSearch?: boolean;
  removeInfoText?: boolean;
  onRowClick?: ((clickedRow: any) => any) | ((clickedRow: any) => Promise<any>);
  onRefresh?(): void;
  rowHasError?(row: any): boolean;
}

export interface SuperTableColumn {
  title: string;
  dataIndex: string;
  width?: "min-content" | "auto";
  align?: "start" | "center" | "end";
  alignVertically?: "start" | "center" | "end";
  sorted?: SortDirection;
  cellTitle?(value: any, row?: any, index?: number): string;
  sorter?(a: any, b: any): number;
  render?(value: any, row: any, index: number): React.ReactNode;
}

export type SortDirection = "ascending" | "descending";

export interface SortInfo {
  col: string;
  direction: SortDirection;
}

const DefaultPageSize = 50;

export function SuperTable(props: SuperTableProps) {
  const [searchValue, setSearchValue] = useState("");
  const [sortInfo, setSortInfo] = useState<SortInfo>();

  const [currentPage, setCurrentPage] = useState(1);

  const earlierRowsAsString = useRef("");
  const [earlierRowsLength, setEarlierRowsLength] = useState(0);

  useEffect(() => {
    if (JSON.stringify(props.rows) === earlierRowsAsString.current) {
      return;
    }

    if (props.multiSelection && props.multiSelection.selected.length !== 0) {
      props.multiSelection.setSelected([]);
    }

    earlierRowsAsString.current = JSON.stringify(props.rows);
  }, [props.rows, props.multiSelection]);

  useEffect(() => {
    let atLeastOneAutoWidth = false;
    let preSortInfo: SortInfo | undefined;

    props.columns.forEach((col) => {
      if (col.width === "auto") {
        atLeastOneAutoWidth = true;
      }
      if (col.sorted) {
        if (preSortInfo) {
          throw new Error(
            "SuperTable error: Exactly one column must have its 'sorted' prop specified"
          );
        }
        preSortInfo = { col: col.title, direction: col.sorted };
      }
    });

    if (!preSortInfo) {
      throw new Error(
        "SuperTable error: Exactly one column must have its 'sorted' prop specified"
      );
    }

    if (!atLeastOneAutoWidth) {
      throw new Error(
        "SuperTable error: At least one of the provided columns must have its width prop specified as 'auto'"
      );
    }

    setSortInfo(preSortInfo);
  }, [props.columns]);

  const columns = useMemo(
    () =>
      props.columns.map((column) => ({
        ...column,
        sorter: column.sorter
          ? column.sorter
          : getStringSorter(column.dataIndex),
      })),
    [props.columns]
  );

  const sortedFilteredRows = useMemo(() => {
    if (!sortInfo) {
      return [];
    }

    const filteredRows = props.rows.filter((row, index) => {
      for (const col of columns) {
        const searchVal = props.searchProps
          ? props.searchProps.searchValue.toLowerCase()
          : searchValue.toLowerCase();
        const dataIndexVal = getPropString(row, col.dataIndex).toLowerCase();

        let renderIsString = false;
        let content;
        if (col.render) {
          content = col.render(getProp(row, col.dataIndex), row, index);
          renderIsString = typeof content === "string";
          if (renderIsString) {
            content = (content as string).toLowerCase();
          }
        }

        if (
          dataIndexVal.includes(searchVal) ||
          (renderIsString && (content as string).includes(searchVal))
        ) {
          return true;
        }
      }
      return false;
    });

    const sortFunction = columns.find(
      (col) => sortInfo.col === col.title
    )?.sorter;

    filteredRows.sort(sortFunction);

    if (sortInfo.direction === "descending") {
      filteredRows.reverse();
    }

    if (filteredRows.length !== earlierRowsLength) {
      setCurrentPage(1);
    }

    setEarlierRowsLength(filteredRows.length);

    return filteredRows;
  }, [
    props.rows,
    searchValue,
    columns,
    sortInfo,
    props.searchProps,
    earlierRowsLength,
  ]);

  const shownRows = useMemo(
    () =>
      sortedFilteredRows.slice(
        (currentPage - 1) * (props.itemsPerPage || DefaultPageSize),
        currentPage * (props.itemsPerPage || DefaultPageSize)
      ),
    [sortedFilteredRows, currentPage, props.itemsPerPage]
  );

  function getRowKey(r: any) {
    return typeof props.rowKey === "string" ? r[props.rowKey] : props.rowKey(r);
  }

  const numberOfPages = useMemo(
    () =>
      Math.ceil(
        sortedFilteredRows.length / (props.itemsPerPage || DefaultPageSize)
      ),
    [sortedFilteredRows, props.itemsPerPage]
  );

  const infoText = useMemo(() => {
    const itemsCount =
      sortedFilteredRows.length +
      " item" +
      (sortedFilteredRows.length !== 1 ? "s" : "");

    const endIndex = currentPage * (props.itemsPerPage || DefaultPageSize);

    const shownCount =
      sortedFilteredRows.length === 0
        ? ""
        : numberOfPages === 1
        ? props.removeSearch
          ? ""
          : "showing all"
        : `showing ${
            (currentPage - 1) * (props.itemsPerPage || DefaultPageSize) + 1
          }-${
            endIndex > sortedFilteredRows.length
              ? sortedFilteredRows.length
              : endIndex
          }`;

    const selectedCount =
      (props.multiSelection?.selected.length || 0) > 0
        ? `${props.multiSelection?.selected.length} selected`
        : "";

    return [itemsCount, shownCount, selectedCount]
      .filter((s) => !!s)
      .join(", ");
  }, [
    props.itemsPerPage,
    props.multiSelection,
    currentPage,
    numberOfPages,
    sortedFilteredRows,
    props.removeSearch,
  ]);

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          padding: "16px 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {!props.removeSearch && (
            <Input
              startAdornment={
                <SearchOutlined
                  style={{ fontSize: "20px", marginRight: "8px" }}
                />
              }
              placeholder="Search items..."
              value={
                props.searchProps ? props.searchProps.searchValue : searchValue
              }
              onChange={(e) =>
                props.searchProps
                  ? props.searchProps.setSearchValue(e.target.value)
                  : setSearchValue(e.target.value)
              }
            />
          )}
          {!props.removeInfoText && (
            <div
              style={{ marginLeft: props.removeSearch ? undefined : "24px" }}
            >
              {infoText}
            </div>
          )}
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {props.onRefresh && (
            <SyncOutlined
              spin={props.isRefreshing}
              onClick={props.onRefresh}
              style={{
                cursor: "pointer",
                marginLeft: "24px",
                fontSize: "20px",
                marginRight: !props.removePagination ? "8px" : undefined,
              }}
            />
          )}
          {!props.removePagination && (
            <Pagination
              id="upper-paginator"
              count={numberOfPages}
              shape="rounded"
              page={currentPage}
              onChange={(_, value) => setCurrentPage(value)}
            />
          )}
        </div>
      </div>
      <div style={{ position: "relative" }}>
        {props.isRefreshing && (
          <div
            style={{
              position: "absolute",
              background: "rgba(255,255,255,0.6)",
              height: "100%",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "92px",
              }}
            >
              <LinearProgress style={{ width: "200px" }} />
            </div>
          </div>
        )}
        <RowGrid columns={columns} multiSelection={props.multiSelection}>
          {props.multiSelection && (
            <div
              style={{
                paddingLeft: "10px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <input
                type="checkbox"
                checked={
                  props.rows.length !== 0 &&
                  props.multiSelection?.selected.length === props.rows.length
                }
                onChange={() => {
                  if (
                    props.multiSelection?.selected.length === props.rows.length
                  ) {
                    props.multiSelection.setSelected([]);
                  } else {
                    props.multiSelection?.setSelected(props.rows);
                  }
                }}
              />
            </div>
          )}
          {columns.map((col) => (
            <ColumnHeader
              key={col.title}
              column={col}
              sortInfo={sortInfo}
              setSortInfo={setSortInfo}
            />
          ))}
          <div
            style={{
              gridColumn: `span ${
                columns.length + (props.multiSelection ? 1 : 0)
              }`,
              borderBottom: `2px solid ${LIGHT_GRAY}`,
            }}
          />
          {shownRows.length === 0 ? (
            <div
              style={{
                gridColumn: `span ${
                  columns.length + (props.multiSelection ? 1 : 0)
                }`,
                textAlign: "center",
                fontSize: "18px",
                margin: "24px",
              }}
            >
              No data to show...
            </div>
          ) : (
            shownRows.map((row, i) => (
              <Row
                row={row}
                columns={props.columns}
                key={getRowKey(row)}
                isSelected={
                  props.selectedRow &&
                  getRowKey(props.selectedRow) === getRowKey(row)
                }
                onRowClick={async () => {
                  if (
                    props.onRowClick &&
                    (window.getSelection()?.toString().length || 0) === 0
                  ) {
                    return await props.onRowClick(row);
                  }
                }}
                getRowKey={getRowKey}
                multiSelection={props.multiSelection}
                hasError={props.rowHasError && props.rowHasError(row)}
                index={i}
              />
            ))
          )}
        </RowGrid>
        {!props.removePagination && (
          <div
            style={{
              marginTop: "16px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Pagination
              count={numberOfPages}
              shape="rounded"
              page={currentPage}
              onChange={(_, value) => {
                setCurrentPage(value);
                document.getElementById("upper-paginator")?.scrollIntoView();
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

function getStringSorter(dataIndex: string) {
  return (a: any, b: any) =>
    getPropString(a, dataIndex).localeCompare(
      getPropString(b, dataIndex),
      "en"
    );
}
