import { useEffect, useMemo } from "react";
import { useState } from "react";
import { doFetch, groupByRequestId, prettyJson } from "../lib/functions";
import { Log } from "../lib/models";
import { logColumns, logGroupsColumns } from "../lib/definitions";
import { SuperTable } from "../components/superTable/superTable";
import { Row } from "../components/superTable/row";
import { RowGrid } from "../components/superTable/rowGrid";

export function LandingPage() {
  const [logs, setLogs] = useState<Log[]>([]);
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    refreshLogs();
  }, []);

  function refreshLogs() {
    setIsRefreshing(true);
    doFetch("GET", "/logs/?n=1000", setLogs, console.log, () =>
      setIsRefreshing(false)
    );
  }

  const logGroups = useMemo(() => groupByRequestId(logs), [logs]);

  return (
    <div>
      <SuperTable
        rows={logGroups}
        rowKey="requestId"
        columns={logGroupsColumns}
        isRefreshing={isRefreshing}
        onRefresh={refreshLogs}
        onRowClick={(row) => (
          <div style={{ marginBottom: "8px" }}>
            <RowGrid columns={logColumns}>
              {row.logs
                .sort((a: any, b: any) => a.timestamp - b.timestamp)
                .map((log: any, index: number) => (
                  <Row
                    key={log._id}
                    row={log}
                    columns={logColumns}
                    onRowClick={() =>
                      prettyJson(
                        log.logType === "authentication"
                          ? log.data.accessToken
                          : log.logType === "error"
                          ? log.data.error?.stack
                          : ["request", "response"].includes(log.logType)
                          ? log.data.body
                          : "",
                        log
                      )
                    }
                    isSelected={false}
                    index={index}
                    getRowKey={() => log._id}
                  />
                ))}
            </RowGrid>
          </div>
        )}
        itemsPerPage={20}
      />
    </div>
  );
}
