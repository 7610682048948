import { useEffect } from "react";
import { useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { getUserInfo, aquireCredentials } from "./lib/authenticationHandler";
import { LandingPage } from "./pages/landingPage";
import { OtherPage } from "./pages/otherPage";

export function App() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [apiVersion, setApiVersion] = useState("");

  useEffect(() => {
    tryLogIn();
    fetch("https://api.ckal.dk")
      .then((res) => res.text())
      .then(setApiVersion);
  }, []);

  async function tryLogIn() {
    try {
      const userInfo = await getUserInfo();
      if (userInfo.email !== "thisbecasper@gmail.com") {
        alert("Unauthorized");
      } else {
        setIsSignedIn(true);
      }
    } catch {
      setIsSignedIn(false);
    }
  }

  async function login() {
    try {
      await aquireCredentials(email, password);
      tryLogIn();
    } catch {
      alert("Could not log in");
    }
  }

  return (
    <div style={{ height: "100vh" }}>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr minmax(0, 1000px) 1fr",
        }}
      >
        <div
          style={{
            marginTop: "48px",
            gridColumn: "2",
            marginBottom: "240px",
          }}
        >
          {isSignedIn ? (
            <Switch>
              <Route exact path="/" component={LandingPage} />
              <Route exact path="/other-page" component={OtherPage} />
              <Route render={() => <Redirect to="/" />} />
            </Switch>
          ) : (
            <div>
              <div>
                <input
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div></div>
                <input
                  placeholder="Password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={(e) => e.key === "Enter" && login()}
                />
              </div>
              <div>
                <button onClick={login}>Log in</button>
              </div>
            </div>
          )}
        </div>
        <div
          style={{
            textAlign: "end",
            margin: "24px",
            color: "gray",
            whiteSpace: "nowrap",
          }}
          onClick={() => window.location.reload()}
        >
          {`${apiVersion}. Dashboard: ${process.env.REACT_APP_VERSION}`}
        </div>
      </div>
    </div>
  );
}
