import JSONPretty from "react-json-pretty";
import { getAccessToken } from "./credentialsHandler";
import { BACKEND_URL, friendlyHttpStatus } from "./definitions";
import { Log, LogGroup } from "./models";

export async function doFetch(
  httpMethod: "GET" | "POST" | "PUT" | "DELETE",
  path: string,
  onOK: (json: any) => void,
  onNotOK: (json: any) => void,
  finallyCallback?: () => void,
  body?: any,
  customUrl?: boolean
) {
  try {
    const response = await fetch(`${customUrl ? path : BACKEND_URL + path}`, {
      headers: await getHeaders(),
      method: httpMethod,
      body: body ? JSON.stringify(body) : undefined,
    });
    if (response.ok) {
      try {
        onOK(await response.json());
      } catch {
        try {
          onOK(await response.text());
        } catch {
          onOK(`${response.status} ${response.statusText}`);
        }
      }
    } else {
      try {
        onNotOK(await response.json());
      } catch (error) {
        onNotOK(response.statusText);
      }
    }
  } catch (error) {
    console.log(error);
    onNotOK("An error occured");
  } finally {
    if (finallyCallback) {
      finallyCallback();
    }
  }
}

export async function getHeaders(extraHeaders?: any) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", await getAccessToken());

  if (extraHeaders) {
    Object.entries(extraHeaders).forEach(([key, val]) =>
      headers.append(key, val + "")
    );
  }

  return headers;
}

export function getTrafficGraphData(logs: Log[]) {
  const temp: { [k in number]: { [k in string]: Log[] } } = {};
  logs.forEach((l) => {
    const timestampRounded = roundTimestampDown(l.timestamp);
    if (!temp[timestampRounded]) {
      temp[timestampRounded] = {};
    }
    if (!temp[timestampRounded][l.requestId]) {
      temp[timestampRounded][l.requestId] = [];
    }

    temp[timestampRounded][l.requestId].push(l);
  });

  const data: { timestamp: number; noRequests: number; logs: Log[] }[] = [];

  for (const ts in temp) {
    const logsOnDay = Object.values(temp[ts]).reduce(
      (prev, curr) => prev.concat(...curr),
      []
    );

    data.push({
      timestamp: Number.parseInt(ts),
      noRequests: logsOnDay.length,
      logs: logsOnDay,
    });
  }

  return data;
}

function roundTimestampDown(timestamp: number) {
  timestamp -= timestamp % (24 * 60 * 60 * 1000); //subtract amount of time since midnight
  timestamp += new Date().getTimezoneOffset() * 60 * 1000; //add on the timezone offset
  return timestamp;
}

export function groupByRequestId(logs: Log[]) {
  const requestIdToLogs: { [k in string]: Log[] } = {};

  logs.forEach((l) => {
    if (!requestIdToLogs[l.requestId]) {
      requestIdToLogs[l.requestId] = [];
    }

    requestIdToLogs[l.requestId].push(l);
  });

  const result: LogGroup[] = [];

  for (const reqId in requestIdToLogs) {
    let error;
    let userInformation;
    let responseStatusCode;
    let httpMethod;
    let path;
    let body;
    let accessToken;
    let application;
    const messages: string[] = [];

    (requestIdToLogs[reqId] || []).forEach((l) => {
      if (l.data.error) error = l.data.error;
      if (l.data.userInformation) userInformation = l.data.userInformation;
      if (l.data.responseStatusCode)
        responseStatusCode = l.data.responseStatusCode;
      if (l.data.httpMethod) httpMethod = l.data.httpMethod;
      if (l.data.path) path = l.data.path;
      if (l.data.body) body = l.data.body;
      if (l.data.accessToken) accessToken = l.data.accessToken;
      if (l.data.message) messages.push(l.data.message);
      if (l.application !== "Server") application = l.application;
    });

    result.push({
      requestId: reqId,
      timestamp: requestIdToLogs[reqId][0].timestamp,
      application: application || "Server",
      logs: requestIdToLogs[reqId],
      error: error,
      userInformation: userInformation,
      responseStatusCode: responseStatusCode,
      httpMethod: httpMethod,
      path: path,
      body: body,
      accessToken: accessToken,
      messages: messages,
      apiVersion: requestIdToLogs[reqId][0].apiVersion || "",
    });
  }

  return (
    result
      // .filter((lg) => !!lg.responseStatusCode)
      .sort((a, b) => b.timestamp - a.timestamp)
  );
}

export function logToType(log: Log) {
  if (log.logType === "request") {
    return "Request";
  } else if (log.logType === "response") {
    return "Response";
  } else if (log.logType === "error") {
    return "Error";
  } else if (log.logType === "functionCall") {
    return "Function call";
  } else if (log.logType === "authentication") {
    return "Authentication";
  } else if (log.logType === "info") {
    return "Info";
  }
  return log.logType;
}

export function logToText(log: Log) {
  if (log.logType === "request") {
    return log.data.httpMethod + " " + log.data.path;
  } else if (log.logType === "response") {
    return `${log.data.responseStatusCode} - ${
      friendlyHttpStatus[log.data.responseStatusCode + ""]
    }`;
  } else if (log.logType === "functionCall") {
    return log.data.message;
  } else if (log.logType === "authentication") {
    return log.data.message;
  }
  return log.data.message;
}

export function getProp(object: any, property: string) {
  if (!object || !property) {
    return undefined;
  }

  const properties = property.split(".");
  let currentObject = object;
  let index = 0;
  let currentProperty = properties[index];

  while (currentObject.hasOwnProperty(currentProperty)) {
    if (index === properties.length - 1) {
      return currentObject[currentProperty];
    } else {
      currentObject = currentObject[currentProperty];
      if (currentObject === undefined) {
        return undefined;
      }
      index++;
      currentProperty = properties[index];
    }
    if (!currentObject) {
      return undefined;
    }
  }

  return undefined;
}

export function getPropString(object: any, property: string): string {
  const value = getProp(object, property);
  if (typeof value === "undefined" || value === null) {
    return "";
  }
  if (typeof value === "object") {
    return JSON.stringify(value);
  }
  if (value && value.toString) {
    return value.toString();
  }
  return value + "";
}

export function timestampToTime(timestamp: number) {
  const date = new Date(timestamp);
  return (
    date.toLocaleTimeString().replace(/\./g, ":") +
    "." +
    date.getMilliseconds().toString().padEnd(3, "0")
  );
}

export function prettifyTimestamp(timestamp: number) {
  const date = new Date(timestamp);

  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ][date.getMonth()];

  const dayPostFix =
    4 <= date.getDate() && date.getDate() <= 20
      ? "th"
      : date.getDate() % 10 === 1
      ? "st"
      : date.getDate() % 10 === 2
      ? "nd"
      : date.getDate() % 10 === 3
      ? "rd"
      : "th";

  return `${date.toLocaleTimeString().replace(/\./g, ":")}, ${month.slice(
    0,
    3
  )} ${date.getDate()}${dayPostFix} ${date.getFullYear()}`;
}

export function prettyJson(json: any, log: Log) {
  if (
    JSON.stringify(json) === "{}" ||
    !["request", "response", "authentication", "error"].includes(log.logType)
  ) {
    return null;
  }

  return !json ? undefined : typeof json === "string" ? (
    <pre
      style={{
        color: "#3e3e3e",
        wordBreak: "break-all",
        whiteSpace: json.includes("\n") ? undefined : "normal",
        overflowY: "auto",
        margin: "8px 16px",
        maxWidth: "600px",
      }}
    >
      {json}
    </pre>
  ) : (
    <div
      style={{
        maxHeight: "300px",
        margin: "8px 16px",
        overflow: "auto",
        paddingRight: "16px",
        maxWidth: "600px",
      }}
    >
      <JSONPretty
        theme={{
          main: "line-height:1.3;color:#4c4c4c;overflow:auto;",
          error: "line-height:1.3;color:#4c4c4c;overflow:auto;",
          key: "color:#f92672;",
          string: "color:#3e3e3e;",
          value: "color:#0086fb;",
          boolean: "color:#ac81fe;",
        }}
        data={json}
      />
    </div>
  );
}
