import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { SortInfo, SuperTableColumn } from "./superTable";

interface ColumnHeaderProps {
  column: SuperTableColumn;
  sortInfo?: SortInfo;
  setSortInfo(sortInfo: SortInfo): void;
}

export function ColumnHeader(props: ColumnHeaderProps) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0 12px",
        height: "24px",
        margin: "6px 0",
        cursor: "pointer",
      }}
      onClick={() => {
        if (props.sortInfo?.col !== props.column.title) {
          props.setSortInfo({
            col: props.column.title,
            direction: "ascending",
          });
        } else {
          props.setSortInfo({
            col: props.column.title,
            direction:
              props.sortInfo.direction === "ascending"
                ? "descending"
                : "ascending",
          });
        }
      }}
    >
      <div
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          marginRight: "6px",
          userSelect: "none",
        }}
        title={props.column.title}
      >
        {props.column.title}
      </div>
      <div style={{ cursor: "pointer", marginTop: "2px" }}>
        {props.sortInfo?.col !== props.column.title ? null : props.sortInfo
            .direction === "ascending" ? (
          <CaretUpOutlined />
        ) : (
          <CaretDownOutlined />
        )}
      </div>
    </div>
  );
}
