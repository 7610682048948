interface TagProps {
  text?: string;
  background?: string;
}

export function Tag(props: TagProps) {
  return (
    <div
      style={{
        padding: "3px 8px",
        color: "white",
        background: props.background || "darkgray",
        borderRadius: "3px",
        width: "min-content",
      }}
    >
      {props.text}
    </div>
  );
}
