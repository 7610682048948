import { SuperTableColumn } from "../components/superTable/superTable";
import { Tag } from "../components/tag";
import {
  logToText,
  logToType,
  prettifyTimestamp,
  timestampToTime,
} from "./functions";
import { Log } from "./models";

export const DOMAIN = "ckal.dk";
export const BACKEND_URL = `https://api.${DOMAIN}/dashboard`;

export const LIGHT_GRAY = "#e3e3e1";

export const LF_CREDENTIALS = "dashboard_creds";
export const AWS_CLIENT_ID = "6nki0f24aj9hrvluekbmkea631";

export const methodToColor: { [k in string]: string } = {
  GET: "#00e000",
  POST: "#0fa4ff",
  PUT: "#ff0ff5",
  DELETE: "red",
};

export const logGroupsColumns: SuperTableColumn[] = [
  {
    title: "ID",
    dataIndex: "requestId",
    render: (requstId: string) => (
      <div style={{ fontFamily: "monospace" }}>{requstId.split("-")[0]}</div>
    ),
  },
  {
    title: "Timestamp",
    dataIndex: "timestamp",
    sorted: "descending",
    render: prettifyTimestamp,
  },
  {
    title: "App",
    dataIndex: "application",
  },
  {
    title: "Method",
    dataIndex: "httpMethod",
    render: (method: string) => (
      <Tag text={method} background={methodToColor[method + ""]} />
    ),
  },
  { title: "Path", dataIndex: "path", width: "auto" },
  {
    title: "Code",
    dataIndex: "responseStatusCode",
    align: "end",
    render: (code) => (
      <div
        style={{
          color:
            code >= 500
              ? "red"
              : code < 300 || code === 304
              ? "#00c500"
              : undefined,
        }}
      >
        {code}
      </div>
    ),
  },
  { title: "User", dataIndex: "userInformation.fullName" },
  { title: "API", dataIndex: "apiVersion", render: (v) => <Tag text={v} /> },
];

export const logColumns: SuperTableColumn[] = [
  {
    title: "a",
    dataIndex: "timestamp",
    alignVertically: "start",
    render: timestampToTime,
  },
  {
    title: "b",
    dataIndex: "",
    alignVertically: "start",
    render: (_, log) => logToType(log),
  },
  {
    title: "c",
    dataIndex: "",
    alignVertically: "start",
    render: (_, log) => logToText(log),
    width: "auto",
  },
  {
    title: "d",
    dataIndex: "data.body",
    width: "auto",
    alignVertically: "start",
    cellTitle: () => "",
    render: (body, log: Log) => {
      let content =
        log.logType === "authentication"
          ? log.data.accessToken
          : log.logType === "error"
          ? log.data.error?.stack
          : ["request", "response"].includes(log.logType)
          ? body || ""
          : "";

      if (JSON.stringify(content) === "{}") {
        content = "";
      }
      return (
        <code>
          {typeof content === "string" ? content : JSON.stringify(content)}
        </code>
      );
    },
  },
];

export const friendlyHttpStatus: { [k in string]: string } = {
  "200": "OK",
  "201": "Created",
  "202": "Accepted",
  "203": "Non-Authoritative Information",
  "204": "No Content",
  "205": "Reset Content",
  "206": "Partial Content",
  "300": "Multiple Choices",
  "301": "Moved Permanently",
  "302": "Found",
  "303": "See Other",
  "304": "Not Modified",
  "305": "Use Proxy",
  "306": "Unused",
  "307": "Temporary Redirect",
  "400": "Bad Request",
  "401": "Unauthorized",
  "402": "Payment Required",
  "403": "Forbidden",
  "404": "Not Found",
  "405": "Method Not Allowed",
  "406": "Not Acceptable",
  "407": "Proxy Authentication Required",
  "408": "Request Timeout",
  "409": "Conflict",
  "410": "Gone",
  "411": "Length Required",
  "412": "Precondition Required",
  "413": "Request Entry Too Large",
  "414": "Request-URI Too Long",
  "415": "Unsupported Media Type",
  "416": "Requested Range Not Satisfiable",
  "417": "Expectation Failed",
  "418": "I'm a teapot",
  "429": "Too Many Requests",
  "500": "Internal Server Error",
  "501": "Not Implemented",
  "502": "Bad Gateway",
  "503": "Service Unavailable",
  "504": "Gateway Timeout",
  "505": "HTTP Version Not Supported",
};
