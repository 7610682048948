import React, { useState } from "react";
import { LIGHT_GRAY } from "../../lib/definitions";
import { SuperTableColumn } from "./superTable";

interface RowGridProps {
  children: React.ReactNode;
  multiSelection?: {
    selected: any[];
    setSelected: React.Dispatch<React.SetStateAction<any[]>>;
  };
  columns: SuperTableColumn[];
}

export function RowGrid(props: RowGridProps) {
  const [hoveredRowKey, setHoveredRowKey] = useState("");

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns:
          (props.multiSelection ? "min-content " : "") +
          props.columns.map((col) => col.width || "min-content").join(" "),
        fontSize: "14px",
        overflow: "auto",
        borderTop: `1px solid ${LIGHT_GRAY}`,
        alignItems: "center",
      }}
    >
      {React.Children.map(props.children, (child) => {
        if (React.isValidElement(child) && typeof child.type !== "string") {
          return React.cloneElement(child, {
            hoveredrowkey: hoveredRowKey,
            sethoveredrowkey: setHoveredRowKey,
          } as any);
        }

        return child;
      })}
    </div>
  );
}
