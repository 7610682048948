import { EnterOutlined } from "@ant-design/icons";
import { LinearProgress } from "@material-ui/core";
import { CSSProperties, useMemo, useState } from "react";
import { LIGHT_GRAY } from "../../lib/definitions";
import { getProp } from "../../lib/functions";
import { SuperTableColumn } from "./superTable";

interface RowProps {
  row: any;
  columns: SuperTableColumn[];
  hoveredrowkey?: string;
  index: number;
  isSelected?: boolean;
  multiSelection?: {
    selected: any[];
    setSelected: React.Dispatch<React.SetStateAction<any[]>>;
  };
  hasError?: boolean;
  getRowKey(r: any): string;
  sethoveredrowkey?(key: string): void;
  onRowClick?: (() => any) | (() => Promise<any>);
}

export function Row(props: RowProps) {
  const [expandContent, setExpandContent] = useState();
  const [isWorking, setIsWorking] = useState(false);

  const { multiSelection, getRowKey, row } = props;

  const isSelected = useMemo(
    () =>
      multiSelection &&
      !!multiSelection.selected.find((r) => getRowKey(r) === getRowKey(row)),
    [row, getRowKey, multiSelection]
  );

  const getCellStyle: (col?: SuperTableColumn) => CSSProperties = (
    col?: SuperTableColumn
  ) => ({
    textAlign: col?.align,
    padding: "4px 12px",
    display: "flex",
    alignItems: col?.alignVertically || "center",
    justifyContent: col?.align,
    height: "calc(100% - 8px)",
    minWidth: "0px",
    background: props.isSelected
      ? "#abd7fd"
      : props.onRowClick && props.hoveredrowkey === props.getRowKey(props.row)
      ? "#dbedfd"
      : props.hasError
      ? "#fb8080"
      : props.index % 2 === 1
      ? "#f3f3f3"
      : undefined,
    cursor: props.onRowClick ? "pointer" : undefined,
  });

  async function handleRowClick() {
    const hadContent = expandContent;
    setExpandContent(undefined);
    if (props.onRowClick) {
      setIsWorking(!hadContent);
      const content = await props.onRowClick();
      setIsWorking(false);
      if (!hadContent) {
        setExpandContent(content);
      }
    }
  }

  function safeToString(value: any) {
    if (typeof value === "object" && value !== null) {
      return JSON.stringify(value);
    }

    return value;
  }

  return (
    <>
      {props.multiSelection && (
        <div
          style={{
            ...getCellStyle(),
            display: "flex",
            alignItems: "center",
            paddingRight: "2px",
          }}
        >
          <input
            type="checkbox"
            onChange={() => {
              if (isSelected) {
                props.multiSelection?.setSelected((selected) =>
                  selected.filter(
                    (r) => props.getRowKey(r) !== props.getRowKey(props.row)
                  )
                );
              } else {
                props.multiSelection?.setSelected((selected) =>
                  selected.concat(props.row)
                );
              }
            }}
            checked={isSelected}
          />
        </div>
      )}
      {props.columns.map((col) => (
        <div
          key={col.title}
          style={getCellStyle(col)}
          onClick={handleRowClick}
          title={
            col.cellTitle
              ? col.cellTitle(
                  getProp(props.row, col.dataIndex),
                  props.row,
                  props.index
                )
              : safeToString(getProp(props.row, col.dataIndex))
          }
          onMouseEnter={() =>
            props.sethoveredrowkey &&
            props.sethoveredrowkey(props.getRowKey(props.row))
          }
          onMouseLeave={() =>
            props.sethoveredrowkey && props.sethoveredrowkey("")
          }
        >
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {col.render
              ? col.render(
                  getProp(props.row, col.dataIndex),
                  props.row,
                  props.index
                )
              : safeToString(getProp(props.row, col.dataIndex))}
          </div>
        </div>
      ))}
      <div
        style={{
          gridColumn: `span ${
            props.columns.length + (props.multiSelection ? 1 : 0)
          }`,
          borderBottom: `1px solid ${LIGHT_GRAY}`,
        }}
      />
      {(expandContent || isWorking) && (
        <div
          style={{
            gridColumn: `span ${
              props.columns.length + (props.multiSelection ? 1 : 0)
            }`,
            display: "flex",
            borderBottom: `1px solid ${LIGHT_GRAY}`,
          }}
        >
          {isWorking ? (
            <div
              style={{
                display: "flex",
                margin: "8px",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <LinearProgress style={{ width: "200px" }} />
            </div>
          ) : (
            <>
              <EnterOutlined
                style={{
                  transform: "scale(-1,1)",
                  color: "2c2c2c",
                  fontSize: "20px",
                  margin: "4px 4px 0 8px",
                }}
              />
              <div style={{ width: "100%" }}>{expandContent}</div>
            </>
          )}
        </div>
      )}
    </>
  );
}
